import { forwardRef, useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Theme, Typography, useMediaQuery } from '@mui/material'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'

import AddButton from '../../components/add-entity-button'
import { ClientSidePaginatedTable } from '../../components/table'
import CustomSearchBox from '../../components/table/custom-search-box'
import TableLink from '../../components/table-link'
import Api from '../../utils/api'
import { convertTimestampToDate } from '../../utils/helper-functions'
import { useDocumentTitle, useSearchData, useTableState } from '../../utils/hooks'
import { filterPlainTextColumn, handleCellClick } from '../../utils/table/table-helpers'

import { AlertContext, UserPermissionsContext } from './../../providers'
import useTableStyles from './domains.styles'

const ApplicationChip = ({ app }: { app: { name?: string; id?: string } }) => {
  const classes = useTableStyles()
  const history = useHistory()

  return (
    <Chip
      label={app.name}
      clickable={true}
      variant="outlined"
      onClick={e => {
        e.stopPropagation()
        history.push(`/applications/${app.id}`)
      }}
      key={app.id}
      className={classes.chips}
    />
  )
}

export const DomainsListView = () => {
  const classes = useTableStyles()
  const history = useHistory()
  const { addAlert } = useContext(AlertContext)
  const searchResultsHook = useSearchData()
  const smallScreenMatches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [domains, setDomains] = useState<IDomainType[]>([])
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  useDocumentTitle('Domains')

  const columns = [
    { name: 'FQDN', options: { filter: false, sort: true } },
    { name: 'Apps', options: { filter: false, sort: false } },
    { name: 'Default App', options: { filter: false, sort: false } },
    {
      name: 'External App',
      options: {
        filter: true,
        sort: false,
        filterOptions: {
          // hardcode values for filter drowdown here to not render empty option in the dropdown list
          names: ['protect', 'insights', 'api'],
          logic: (location: string, filters: string[]) => filterPlainTextColumn(location, filters),
          fullWidth: smallScreenMatches ? true : false,
        },
      },
    },
    { name: 'Modified', options: { searchable: false, filter: false, sort: true } },
  ]

  const fetchDomainsData = async () => {
    const retreivedDomains = await Api.get('/api/domains')
    const applications = await Api.get('/api/applications')

    if (retreivedDomains.error) {
      addAlert({ alertType: 'error', message: retreivedDomains?.message || 'Could not load domains' })
      return Promise.reject(retreivedDomains)
    }

    if (applications.error) {
      addAlert({ alertType: 'error', message: applications?.message || 'Could not load applications' })
    }

    const getDefaultApplication = (applicationId: number) => {
      return applications.filter((application: IApplicationType) => application.id === applicationId)[0]
    }

    const getDomainApplications = (appsIds: number[]) => {
      const domainApplications: Array<{}> = []

      appsIds.forEach((appId: number) => {
        applications.forEach((app: IApplicationType) => {
          if (app.id === appId) {
            domainApplications.push(app)
          }
        })
      })

      return domainApplications
    }

    const allRowData: Array<Array<number | string | React.ReactNode>> = retreivedDomains.map((domain: IDomainType) => {
      const defaultApplication = !applications.error ? getDefaultApplication(domain.defaultApplication) : ''

      const domainApps: Array<{ name?: string; id?: string }> = !applications.error ? getDomainApplications(domain.apps) : []

      const allApplications = (
        <TableLink
          to="/applications"
          onClick={e => {
            e.stopPropagation()
          }}
        >
          All
        </TableLink>
      )

      const appChipsMaxCount = 5
      let domainAppNames
      if (!applications.error) {
        if (domainApps.length > 0) {
          if (domainApps.length < appChipsMaxCount) {
            domainAppNames = domainApps.map(app => {
              return <ApplicationChip app={app} />
            })
          } else {
            domainAppNames = (
              <>
                {domainApps.slice(0, appChipsMaxCount).map(app => {
                  return <ApplicationChip app={app} />
                })}
                <Typography variant="caption">+{domainApps.length - appChipsMaxCount} more</Typography>
              </>
            )
          }
        } else {
          domainAppNames = allApplications
        }
      } else {
        domainAppNames = ''
      }

      let defaultApplicationName
      if (!applications.error) {
        defaultApplicationName = (
          <TableLink
            to={`/applications/${defaultApplication?.id}`}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            {defaultApplication?.name ?? ''}
          </TableLink>
        )
      } else {
        defaultApplicationName = ''
      }

      return [domain.fqdn, domainAppNames, defaultApplicationName, domain?.externalApp, convertTimestampToDate(domain.updatedAt)]
    })

    return Promise.resolve({ data: allRowData, setEntities: () => setDomains(retreivedDomains) })
  }

  const NewDomainLink = forwardRef((linkProps: any, ref: any) => <Link to="/domains/new" ref={ref} {...linkProps} />)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFetch = useCallback(fetchDomainsData, [refreshCounter])
  const state = useTableState(memoizedFetch)

  return (
    <Grid justifyContent="center" container={true} data-cy="domainsList" className={classes.tableContainer}>
      <Grid container={true} justifyContent="flex-end">
        <UserPermissionsContext.Consumer>
          {permissions => (
            <AddButton
              userPermissions={permissions}
              newComponentLink={NewDomainLink}
              entityName="Domains"
              buttonName="Add Domain"
              dataCy="addBtn"
              dataTestId="addBtn"
            />
          )}
        </UserPermissionsContext.Consumer>
      </Grid>

      <Grid item={true} xs={12}>
        <ClientSidePaginatedTable
          title="Domains"
          idList={domains != null ? domains.map(d => ({ id: d.id })) : []}
          entityDeleteEndpoint="/api/domains"
          refresh={() => setRefreshCounter(c => c + 1)}
          preserveSearchResults={{ ...searchResultsHook }}
          columns={columns}
          loading={state.loading}
          error={state.error}
          data={state.data}
          options={{
            filter: true,
            filterType: 'dropdown',
            onCellClick: (_: any, cellMeta: MuiTableCellMetaType) => handleCellClick(history, domains, 'domains', cellMeta, searchResultsHook.abort),
            customSearchRender: (searchText, handleSearch) => {
              return (
                <CustomSearchBox
                  searchText={searchText}
                  handleSearch={handleSearch}
                  entityPath="domains"
                  abortToken={searchResultsHook.abort}
                  isValidId={(id: number) => !!domains?.find(domain => domain.id === id)}
                />
              )
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DomainsListView
