import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { styles as sharedStyles } from '../../shared-styles/form.styles'

export const styles = (theme: Theme) =>
  createStyles({
    foldersHelperText: {
      marginTop: 0,
    },
    addPreferences: {
      marginTop: theme.spacing(2),
    },
    cardActions: {
      flex: 'initial',
    },
    bottomSpacing: {
      marginBottom: '15px',
    },
    togglePasswordBtn: {
      marginBottom: theme.spacing(),
    },
    rightMargin: {
      marginRight: theme.spacing(2),
    },
    inviteBtnGroup: {
      [theme.breakpoints.only('xs')]: {
        width: '430px',
      },
    },
    noTasks: {
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
    ...sharedStyles(theme),
  })

const useStyles = makeStyles(styles)
export default useStyles
